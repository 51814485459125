.ant-form-item {
  font-family: $font-family-sans-serif !important ;
}
.ant-form-item-label {
  color: $federal-blue !important ;
  font-family: $font-family-sans-serif !important ;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-form-item .ant-form-item-label >label {
  color: $federal-blue ;
  font-size: 0.875rem ;
}
.ant-input {
  background: #f9f9f9 !important;
  font-family: $font-family-sans-serif !important ;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid #D1D1D0;
  background: #f9f9f9 !important;
  height: 45px;

  &:hover {
    border: 1px solid #D1D1D0 !important;
  }
}

.ant-form-item .ant-form-item-explain-error {
  font-family: $font-family-sans-serif !important ;
}

.ant-btn {
  border-radius: 4px;
  // background-color: $color-primary;
  padding: 0.625rem;
  // color: $white; 
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.313rem;
  text-transform: capitalize;
  height: auto;
  // border: none !important;
  letter-spacing: 0.024px;
  // svg {
  //   path {
  //     fill: $white !important;
  //   }
  // }

  &:hover,
  &:active {
    //background-color: $color-primary !important;
    // color: $white !important;
    // outline: none !important;
    // border: none !important;

    &:disabled {
      background-color: $gray-400 !important;
      color: $white !important;
    }
  }

  &:disabled {
    background: $gray-400;
    color: $white;

    svg {
      path {
        fill: $white !important;
      }
    }
  }
}

// .ant-checkbox-wrapper
// hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-1vr7spz).
// ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inne

.ant-checkbox .ant-checkbox-inner {
  ::after {
    border: 2px solid brown !important;
  }
}

.ant-checkbox-wrapper .ant-checkbox {
  background-color: #fff;
  border: 1.5px solid #a4a4a6 !important;
  outline: none !important;
  border: none !important;

  .ant-checkbox-inner {
    width: 0.938rem !important;
    height: 0.938rem !important;
    border: 1.5px solid #a4a4a6 !important;
    ::after {
      border: 2px solid green !important;
    }
  }
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  border-color: $color-primary !important ;
  background-color: $color-primary !important;
  width: 0.938rem !important;
  height: 0.938rem !important;
  &:after {
    border: 2px solid $white;
    border-top: 0;
    border-inline-start: 0;
  }
}

