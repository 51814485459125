@font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 400;
    src: url("./src/BeVietnamPro-Regular.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 400;
    src: url("./src/BeVietnamPro-Italic.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 250;
    src: url("./src/BeVietnamPro-Thin.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 250;
    src: url("./src/BeVietnamPro-ThinItalic.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 275;
    src: url("./src/BeVietnamPro-ExtraLight.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 275;
    src: url("./src/BeVietnamPro-ExtraLightItalic.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 300;
    src: url("./src/BeVietnamPro-Light.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 300;
    src: url("./src/BeVietnamPro-LightItalic.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 500;
    src: url("./src/BeVietnamPro-Medium.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 500;
    src: url("./src/BeVietnamPro-MediumItalic.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 600;
    src: url("./src/BeVietnamPro-SemiBold.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 600;
    src: url("./src/BeVietnamPro-SemiBoldItalic.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 700;
    src: url("./src/BeVietnamPro-Bold.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 700;
    src: url("./src/BeVietnamPro-BoldItalic.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 800;
    src: url("./src/BeVietnamPro-ExtraBold.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 800;
    src: url("./src/BeVietnamPro-ExtraBoldItalic.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: normal;
    font-weight: 900;
    src: url("./src/BeVietnamPro-Black.ttf");
  }
  @font-face {
    font-family: BeVietnamPro;
    font-style: italic;
    font-weight: 900;
    src: url("./src/BeVietnamPro-BlackItalic.ttf");
  }
  