@use 'assets/styles/components/variables' as *;


.user-kyc-form-container{

    display: grid;
    grid-template-columns: 249px 1fr;
    column-gap: 48px;
    height: calc(100vh - 239px);
    overflow-y: auto;
    .section-container {
        background-color: #F2F2F2;
        padding: 40px 20px;
        gap: 16px;
        display: flex;
        flex-direction: column;
 

        .sideBar-section{
            padding: 0px 10px;
            color: #030507;
            font-size: 0.875rem;
            line-height: 1.5rem;
            font-style: normal;
            font-weight: 400;
            &.active{
            border-left: 2px solid $color-primary;
            color: #3BAEFE;
            }
        }
    }
    
    .form-container {
        padding: 0px 148.5px;
        margin-top: 40px;
        height: 100%;
        overflow-y: auto;
    }


    .ant-input {
        background-color: $white !important;
        border: 1px solid #E5E5E5 !important;
        color: #061b01;
        border-radius: 0.25rem;
        border: 0;
        height: 2.313rem;
        display: flex;
        //border: 1px solid $success-box  !important;
        padding: 4px 11px 4px;
    }
    
}

.input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;

    @media screen and (max-width: 719px) {
        grid-template-columns: 1fr;
        gap: 0.635rem;
    }
}


.ant-picker {
    background-color: #f9f9f9;
    border: 1px solid #E5E5E5;
    color: #061b01;
    border-radius: 0.25rem;
    border: 0;
    height: 2.313rem;
    display: flex;

    .ant-picker-input>input {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: start !important;
        color: #000000;
    }
}

.resetBtn {
    border-radius: 4px;
    border: 1px solid $color-success;
    color: $color-success;
    background: $white;
    display: flex;
    width: 185px !important;
    height: 40px;
    padding: 14px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0
}

.submitBtn {
    border-radius: 4px;
    border: 1px solid $color-success;
    color: $white;
    background: $color-success;
    display: flex;
    width: 185px !important;
    height: 40px;
    padding: 14px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    &:hover {
        border: 1px solid $color-success !important;
        color: $white !important;
        background: $color-success !important;
    }
}

.ant-radio-wrapper {
    .ant-form input {
        border: 1px solid $color-success !important;
        background-color: $color-success;
    }

    .ant-radio-checked .ant-radio-inner {
        border: 1px solid $color-success !important;
        background-color: $color-success;

        &:hover {
            border: 1px solid $color-success !important;
            color: $white !important;
            background: $color-success !important;
        }
    }
}



.ant-select.ant-select-in-form-item {
    min-height: 2.313rem !important;

}


.account-section {
    margin: 20px 0;
    gap: 20px;
    display: grid;
    width: 100%;
}

.section-container {
    background-color: $white;
    border: 1px solid #D1D5DB;

    @media screen and (min-width: 991px) {
        padding: 30px;
    }

    @media screen and (max-width: 990px) {
        padding: 1rem;
    }

}

.formContainer {
    border-bottom: 1px solid  #3984FF;
    padding: 20px 0;
}

.previewContainer {
    width: 1000px;
    border-radius: 8px;
    background: $white;
    padding: 0px 47px;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    .field-item {
        border-bottom: 0.5px solid $gray-500;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 0px;
    }
}

