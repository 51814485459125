.app-container {
  width: 100vw;
  background: $white;
  min-height: 100vh;

  .mainDashboard {
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    overflow: hidden;

    .sideBar-Outlet {
      display: grid;
      grid-template-columns: 240px auto;
      height: calc(100vh - $header-height);
      background: $white3;
      overflow-y: auto;

      @media (min-width: 991px) {
        gap: calc(30px - 1rem);
      }
      @media (max-width: 991px) {
        display: block;
      }

      .dashboardSidebar {
        background: $white;
      }

      @media (max-width: 600px) {
        grid-template-columns: 100%;
      }

      .main-container {
        padding: 1.875rem 1.875rem 0 1rem;
        height: calc(100vh - 100px);
        overflow-y: auto;

        .content {
          min-height: 50vh;
        }
      }
    }

    .NoSideBar-Outlet {
      display: grid;
      grid-template-columns: 1fr;
      height: calc(100vh - $header-height);
      background: $white3;
      overflow-y: auto;

      @media (min-width: 991px) {
        gap: calc(30px - 1rem);
      }
      @media (max-width: 991px) {
        display: block;
      }

      .dashboardSidebar {
        background: $white;
      }

      @media (max-width: 600px) {
        grid-template-columns: 100%;
      }

      .main-container {
        padding: 1.875rem 1.875rem 0 1rem;
        height: calc(100vh - 100px);
        overflow-y: auto;

        .content {
          min-height: 50vh;
        }
      }
    }
  }
}
