//
// Theme style
//

// Initialize
@import "init";

// Components
@import "components/custom/components.scss";
@import "components/components";

// Layout
@import "layout/layout";

@import url("../fonts/beVietnamPro.css");

html {
  scroll-behavior: smooth;
}
body {
  font-family: $font-family-sans-serif;
  background: $white;
  color: $white;
  line-height: $line-height-base;
  min-height: 100vh;
  font-weight: $font-weight-normal;
}
