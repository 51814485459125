//
//header
//
header {
  height: $header-height;
}

.layout-dark-container {
  // height: 458px !important;
  padding: 0rem 1em;

  @media screen and (max-width: 991px) {
    margin-top: -10px;
    height: 50vh !important;
  }

  @media screen and (min-width: 992px) {
    padding: 0rem 2.5rem;
  }
}

.blue-bar {
  background-color: $blue-600 !important;
  margin: 0rem -1em;
  height: 0.625rem;
  @media screen and (min-width: 992px) {
    margin: 0rem -2.5rem;
  }
}

.nav {
  height: 5rem;
  padding: 1rem 0;
  // display: grid;
  // align-items: end;
  transition: var(--transition);
  background: transparent;
}

.nav-center {
  width: 100%;
  // max-width: 1440px;
  // padding: 0rem 1rem;
  margin: 0 auto;
  z-index: 1;
  display: flex;

  @media screen and (min-width: 992px) {
    // display: grid;
    // grid-template-columns: 1fr 2fr 1fr;
    // padding: 0rem 2.5rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 0;
  }
}

.nav-header {
  display: grid;
  grid-template-columns: auto;
  align-items: center;

  @media screen and (max-width: 992px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.hamburger-button {
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.bar {
  width: 30px;
  height: 4px;
  background: $blue-500;
  margin: 6px 0;
  transition: 0.4s;
}

.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.open .bar:nth-child(2) {
  opacity: 0;
}

.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.nav-logo {
  width: 127.216px;
  height: 20px;
}

.nav-links {
  display: none;

  @media screen and (min-width: 992px) {
    display: flex;
    // grid-template-columns: repeat(5, 1fr);
    white-space: nowrap;
    column-gap: 30px;
  }
}

.nav-button-container {
  display: none;
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    justify-self: center;
  }
}

@media screen and (min-width: 992px) {
  .nav-bar-margin-right {
    margin-right: 6rem;
  }

  .nav-bar-margin-left {
    margin-left: 6rem;
  }

  .nav-link-container {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    justify-self: center;
  }

  .nav-links a {
    text-transform: capitalize;
    transition: var(--transition);
    text-decoration: none;
    color: $white;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.05rem;
    display: inline-flex;
  }

  .nav-links a::after {
    content: "";
    width: 0%;
    height: 3px;
    background-color: var(--clr-primary-5);
    margin: 0.3rem auto;
    display: grid;
  }

  .active::after {
    content: "";
    width: 100%;
    height: 3px;
    margin: 0.3rem auto;
    display: grid;
  }

  .active:hover::after {
    width: 100%;
    transition: width 0.3s linear;
  }

  .nav-links a:hover::after {
    width: 100%;
    transition: width 0.3s linear;
  }

  .nav-btn-link {
    border: 1px solid $blue-100;
    color: $blue-600;
    cursor: pointer;
    background: $blue-100;
    border-radius: 4px;
    padding: 10px 20px;
    gap: 10px;
    // width: 150px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.05rem;
    text-align: center;
    height: 40px;
  }

  .nav-btn-link-2 {
    border: 1px solid $blue-600;
    color: $white;
    cursor: pointer;
    background: $blue-600;
    border-radius: 4px;
    padding: 10px 20px;
    gap: 10px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.05rem;
    text-align: center;
    height: 40px;
  }
}

.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  // box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background: $white;
  padding: 0 2.5rem;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background: $blue-600;
  transition: 0.4s;
  z-index: 100;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.show-sidebar {
  right: 0;
}

.sidebar-menu {
  list-style: none;
  padding: 4rem 0 0 0;
  margin: 0;
  text-align: center;
}

.sidebar-menu li {
  padding: 15px;
  text-align: right;
}

.sidebar-menu a {
  font-size: 1rem;
  text-transform: capitalize;
  transition: var(--transition);
  color: $white;
  display: inline-block;
  padding: 0.5rem 0;
  text-decoration: none;
  text-align: center;
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
}

.content-container {
  min-height: 50vh;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 1rem;

  // @media screen and (min-width: 992px) {
  //   margin: 0 2rem;
  // }
}
